/* @import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* colors */
:root {
  --primary: 354, 100%, 60%;
  --primary-light: 354, 100%, 60%, 0.06;
  --primary-dark: 354, 74%, 45%, 1;
  --primary-foreground: 218, 12%, 18%, 1;
  --secondary: 240, 18%, 97%, 1;
  --secondary-light: 214, 41%, 97%, 1;
  --secondary-foreground: 212, 11%, 45%, 1;
  --success: 126, 100%, 32%, 1;
  --success-light: 126, 100%, 39%, 0.06;
  --success-lighter: 126, 100%, 39%, 1;
  --success-foreground: 126, 100%, 17%, 1;
  --success-dark: 126, 100%, 5%, 1;
  --blue: 214, 46%, 37%, 0.1;
  --blue-light: 214, 46%, 38%, 0.1;
  --blue-lighter: 180, 100%, 39%, 1;
  --grey: 220, 16%, 59%, 0.66;
  --grey-border: 213, 18%, 38%, 1;
}


/* fonts */
.urbanist {
  @apply font-urbanist;
}

.inter {
  @apply font-inter;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Custom scrollbar styles */
.thin-scrollbar::-webkit-scrollbar {
  width: 1px !important;
  height: 0.5px !important;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}

.thin-scrollbar:hover::-webkit-scrollbar {
  opacity: 1;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: hsl(var(--primary));
  border-radius: 999px;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--primary)) transparent;
}



/* home page container  */
.controlled-grid {
  display: grid;
  grid-template-columns:
    [full-width-start] 5rem [content-start] minmax(0, 1fr) [content-end] 5rem [full-width-end];
}


.controlled-grid > *:not(.full-width) {
  grid-column: content;
}

.controlled-grid > .full-width {
  grid-column: full-width;
}

@media (max-width: 768px) {
  .controlled-grid > .full-width-mobile {
    grid-column: full-width;

  }
}

@media (min-width: 1280px) {
  .controlled-grid {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;

  }
}

textarea::placeholder,
input::placeholder {
  color: #6b7280;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, system-ui, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 4px;
  scrollbar-color: #c9ced5;
}

::-webkit-scrollbar-track {
  background: #f5f5f8;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(var(--grey-border));
  border-radius: 2rem;
}

@keyframes selfRotate {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(90deg);
  }

  50% {
    transform: rotate3d(90deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.self-rotate-animation {
  animation: selfRotate 3s linear infinite;
  transform-origin: center center;
  transform-style: preserve-3d;
}

@keyframes sway {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@keyframes moveToCenter {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(calc(50% - 225px));
  }
}

@keyframes rotateWheel {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.car-animation-container {
  animation: moveToCenter 3s linear forwards;
}

.wheel {
  animation: rotateWheel 3.5s ease-out;
  animation-fill-mode: forwards;
}

@keyframes rotateWheelhero {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.wheelhero {
  animation: rotateWheelhero 5s linear infinite;
}



@keyframes blink {

  0%,
  90% {
    d: path("M10.0004 3.33325C13.5754 3.33325 16.5529 5.35742 18.8879 9.29492L19.0712 9.61075L19.1087 9.69409L19.1337 9.76325L19.1454 9.80909L19.157 9.87742L19.1662 9.96075V10.0524L19.1545 10.1449C19.1491 10.1759 19.1418 10.2065 19.1329 10.2366L19.1004 10.3266L19.0704 10.3891L19.057 10.4141C16.7537 14.4441 13.807 16.5641 10.2612 16.6633L10.0004 16.6666C6.33705 16.6666 3.30288 14.5424 0.943716 10.4133C0.871807 10.2874 0.833984 10.1449 0.833984 9.99992C0.833984 9.85494 0.871807 9.71247 0.943716 9.58659C3.30288 5.45742 6.33705 3.33325 10.0004 3.33325ZM10.0004 7.49992C9.33734 7.49992 8.70146 7.76331 8.23261 8.23215C7.76377 8.70099 7.50038 9.33688 7.50038 9.99992C7.50038 10.663 7.76377 11.2988 8.23261 11.7677C8.70146 12.2365 9.33734 12.4999 10.0004 12.4999C10.6634 12.4999 11.2993 12.2365 11.7681 11.7677C12.237 11.2988 12.5004 10.663 12.5004 9.99992C12.5004 9.33688 12.237 8.70099 11.7681 8.23215C11.2993 7.76331 10.6634 7.49992 10.0004 7.49992Z"
      );
  }

  91%,
  100% {
    d: path("M2 10C5 7 15 7 18 10C15 13 5 13 2 10Z");
  }
}

.icon-eye .eye {
  animation: blink 0.8s infinite;
}


@keyframes heartFloat {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -100px);
  }
}

.heart-particle {
  animation: heartFloat 3s ease-out forwards;
  pointer-events: none;
}


.swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-pagination-bullet-active {
  background-color: #FF3448 !important;
}



@keyframes carDriveIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.car-animation-container {
  animation: carDriveIn 3s ease-out forwards;
}

.car-centered {
  transform: translateX(0);
}

@keyframes wheelRotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.wheel {
  animation: wheelRotation 2.5s linear infinite;
  animation-play-state: running;
}

.car-centered .wheel {
  animation-play-state: paused;
}


.button-container {
  position: relative;
  display: inline-block;
}

.button-container::before {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0.7;
  animation: button 2s infinite;
}

.original-button {
  position: relative;
}

@keyframes pulse-button {

  0%,
  100% {
    opacity: 0.2;
    transform: scale(1.2);
  }

  50% {
    opacity: 0.3;
    transform: scale(1.05);
  }
}

.safari-fix {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}


.vehicle-description p {
  font-family: 'Inter', sans-serif !important;
  color: var(--primary-foreground) !important;
  font-size: 17px !important;
  line-height: 24px !important;

}